import useClipboard from 'react-use-clipboard'
import { Tooltip } from 'evergreen-ui'
import React from 'react'

export default function CopyToClipboard ({
  text,
  successDuration = 5000,
  children = null,
  titleIdle = 'Copy',
  titleCopied = 'Copied!'
}) {
  const [isCopied, setCopied] = useClipboard(text, {
    successDuration
  })

  return (
    <Tooltip content={isCopied ? titleCopied : titleIdle}>
      {React.cloneElement(children, { onClick: setCopied })}
    </Tooltip>
  )
}
