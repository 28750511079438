const vaults = [
  {
    address: '0x463F9ED5e11764Eb9029762011a03643603aD879',
    name: 'stETH Volatility Vault v2.0',
    symbol: 'stETHvv',
    decimals: 18,
    asset: {
      name: 'Liquid staked Ether 2.0',
      symbol: 'stETH',
      decimals: 18,
      oracle: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'
    }
  },
  {
    address: '0x5FE4B38520e856921978715C8579D2D7a4d2274F',
    name: 'stETH ETHphoria Vault',
    symbol: 'stETHev',
    decimals: 18,
    asset: {
      name: 'Liquid staked Ether 2.0',
      symbol: 'stETH',
      decimals: 18,
      oracle: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'
    }
  },
  {
    address: '0x287f941aB4B5AaDaD2F13F9363fcEC8Ee312a969',
    name: 'aUSDC FUD Vault',
    symbol: 'aUSDCfv',
    decimals: 6,
    asset: {
      name: 'Aave Ethereum USDC',
      symbol: 'aEthUSDC',
      decimals: 6,
      oracle: '0x8fffffd4afb6115b954bd326cbe7b4ba576818f6'
    }
  },
  {
    address: '0x057d02Cf8C372457F7169Af0cB44aFD129f1b0c0',
    name: 'USDbC FUD Vault',
    symbol: 'aBasUSDbCfv',
    decimals: 6,
    asset: {
      name: 'Aave Base USDC',
      symbol: 'aBasUSDbC',
      decimals: 6,
      oracle: '0x7e860098f58bbfc8648a4311b374b1d669a2bc6b'
    },
    network: 'base'
  },
  {
    address: '0x749320d7e0b22dD54c529d83442de99d170C161c',
    name: 'aUSDC FUD Vault',
    symbol: 'aUSDCfv',
    decimals: 6,
    asset: {
      name: 'Aave Ethereum USDC',
      symbol: 'aEthUSDC',
      decimals: 6,
      oracle: '0x8fffffd4afb6115b954bd326cbe7b4ba576818f6'
    },
    deprecated: true
  },
  {
    address: '0xbaB1E772d70300422312dfF12dADdCB60864bd41',
    name: 'stETH Volatility Vault v1.0',
    symbol: 'stETHvv',
    decimals: 18,
    asset: {
      name: 'Liquid staked Ether 2.0',
      symbol: 'stETH',
      decimals: 18,
      oracle: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'
    },
    deprecated: true
  }
]

export default vaults
