/**
 * Fetches JSON data from a specified URL and returns the parsed JSON object.
 *
 * @async
 * @param {string} url - The URL from which to fetch JSON data.
 * @returns {Promise<any>} A promise that resolves with the parsed JSON object from the response.
 * @throws {Error} Throws an error if the response status is not 200, with the response's error message.
 */
export default async function fetchJSON (url) {
  const response = await fetch(url)
  const data = await response.json()

  if (response.status !== 200) {
    throw new Error(data.message)
  }

  return data
}
