import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import useSWRMutation from 'swr/mutation'
import {
  Badge, Button,
  DuplicateIcon,
  Heading,
  majorScale,
  Pane,
  ShareIcon,
  Tab, Table,
  TabNavigation, Textarea, TickCircleIcon,
  toaster,
  Tooltip, WarningSignIcon
} from 'evergreen-ui'
import CopyToClipboard from '../../components/CopyToClipboard'
import vaults from '../../constants/vaults'
import getStrategyByAddress from '../../utils/getStrategyByAddress'
import fetchJSON from '../../utils/fetchJSON'

const MILLION = '1000000000000000000000000'

export default function StrategyPage ({ strategy }) {
  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/strategies/${strategy.address}/simulations`,
    fetchJSON
  )

  const formatBigNumber = (value, decimals, pattern = '') => {
    const asBN = new BigNumber(value)
    const baseUnit = new BigNumber(10).pow(decimals)
    const formatted = asBN.dividedBy(baseUnit)
    return numeral(formatted.toString()).format(pattern)
  }

  const getTVL = strategy => {
    const totalTokens = new BigNumber(strategy.totalAssets).plus(strategy.totalIdleAssets)
    const baseUnit = new BigNumber(10).pow(strategy.asset.decimals)
    const formatted = totalTokens.dividedBy(baseUnit)
    const price = new BigNumber(strategy.assetPrice.value)
    return numeral(formatted.multipliedBy(price).toString()).format('$0,0.00')
  }

  if (error && !isMutating) {
    toaster.danger(error.message)
  }

  return (
    <>
      <Head>
        <title>Yield Operations</title>
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <Pane display='flex' flexDirection='column' minHeight='100vh'>
        <Pane
          is='nav'
          position='sticky'
          top={0}
          backgroundColor='white'
          zIndex={10}
          height={majorScale(8)}
          borderBottom='muted'
          paddingX={majorScale(5)}
        >
          <Pane display='flex'>
            <Link href='/'>
              <Image src='/yield.svg' width={96} height={48} style={{ cursor: 'pointer' }} />
            </Link>
          </Pane>
        </Pane>

        <Pane flex={1} marginX={12}>
          <TabNavigation marginY={16} flexBasis={240}>
            {vaults.map(vault => (
              <Tab
                key={vault.address}
                id={vault.address}
                is='a'
                href={`/strategies/${vault.address}`}
                isSelected={vault.address.toLowerCase() === strategy.address.toLowerCase()}
              >
                <Pane position='relative'>
                  <span>
                    {vault.name}
                    {vault.deprecated && (
                      <Pane position='absolute' top={-18} right={-24}>
                        <Badge color='yellow'>Old</Badge>
                      </Pane>
                    )}
                  </span>
                </Pane>
              </Tab>
            ))}
          </TabNavigation>

          <Pane padding={16} background='tint1' flex='1' display='flex'>
            <Pane
              key={strategy.address}
              id={`panel-${strategy.name}-${strategy.address}`}
              role='tabpanel'
              aria-labelledby={strategy.address}
              width='100%'
            >
              <Pane display='flex' marginY={32} marginX={12}>
                <Heading size={700} flex={1}>
                  {strategy.name}
                  &nbsp;
                  &nbsp;
                  <small>
                    <CopyToClipboard text={strategy.address} titleIdle='Copy address'>
                      <DuplicateIcon size={12} cursor='pointer' />
                    </CopyToClipboard>
                  </small>
                  &nbsp;
                  &nbsp;
                  <small>
                    <Tooltip content='See on Etherscan'>
                      <Pane display='inline'>
                        <Link
                          href={`https://etherscan.io/address/${strategy.address}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          color='neutral'
                        >
                          <ShareIcon size={12} cursor='pointer' />
                        </Link>
                      </Pane>
                    </Tooltip>
                  </small>
                  &nbsp;
                  &nbsp;
                </Heading>

                <Pane>
                  <Button appearance='primary' onClick={trigger} disabled={isMutating}>
                    {isMutating ? 'Loading...' : 'Simulate End Round'}
                  </Button>
                </Pane>
              </Pane>

              <Pane display='flex' marginY={32}>
                <Pane elevation={1} flex={1} marginX={12}>
                  <Table.Row>
                    <Heading size={500} margin={16} paddingTop={16}>
                      General
                    </Heading>
                  </Table.Row>

                  <Table.Row>
                    <Table.TextCell>Current Round:</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>{strategy.currentRoundId}</strong>
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Total Value Locked (USD):</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {getTVL(strategy)}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Shares:</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>{formatBigNumber(strategy.totalSupply, strategy.decimals, '0,0.000')}</strong> {strategy.symbol}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Total Assets:</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>{formatBigNumber(strategy.totalAssets, strategy.asset.decimals, '0,0.000')}</strong> {strategy.asset.symbol}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Total Idle Assets:</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>{formatBigNumber(strategy.totalIdleAssets, strategy.asset.decimals, '0,0.000')}</strong> {strategy.asset.symbol}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>{strategy.asset.symbol} Price</Table.TextCell>
                    <Table.TextCell
                      textAlign='right'
                    >{numeral(strategy.assetPrice.value).format('$0,0.00')}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Queue Size</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>{strategy.currentQueueSize}</strong>
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Deposit Window</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {strategy.isProcessingDeposits
                        ? (
                          <Tooltip content='The vault is closed for deposits and waiting to be started'>
                            <Badge color='red'>
                              Closed / Waiting a new Round
                            </Badge>
                          </Tooltip>
                          )
                        : (
                          <Tooltip content='A new round started and the vault is receiving deposits'>
                            <Badge color='green' cursor='default'>
                              Receiving Deposits
                            </Badge>
                          </Tooltip>
                          )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Investor Allowance</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      <strong>
                        {strategy.investorAllowance > MILLION
                          ? <Badge color='green'>Max Allowance</Badge>
                          : strategy.investorAllowance === '0'
                            ? <Badge color='red'>No Allowance</Badge>
                            : (
                              <>
                                {formatBigNumber(strategy.investorAllowance, strategy.asset.decimals, '0,0.000')} {strategy.asset.symbol}
                              </>
                              )}
                      </strong>
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>
                      Cap Usage
                    </Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {parseFloat(strategy.cap.total) === 0
                        ? (
                          <Badge color='red'>Uncapped</Badge>
                          )
                        : (
                          <>
                            {formatBigNumber(strategy.cap.spent, strategy.decimals, '0,0.000')}
                            &nbsp;/&nbsp;
                            <strong>
                              {formatBigNumber(strategy.cap.total, strategy.decimals, '0,0.000')}
                            </strong>
                            &nbsp;{strategy.symbol}
                            &nbsp;({strategy.cap.usage})
                          </>
                          )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>
                      Fee
                    </Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {strategy.fee.humanized}
                    </Table.TextCell>
                  </Table.Row>
                </Pane>

                <Pane elevation={1} flex={1} marginX={12}>
                  <Table.Row>
                    <Heading size={500} margin={16} paddingTop={16}>
                      Simulations
                    </Heading>
                  </Table.Row>

                  <Table.Row>
                    <Table.TextCell>Lido Weekly Yield</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {numeral(data.diff.percentage).format('0.000%')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Weekly Yield generated by 1 share</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {numeral(data.diff.percentage).format('0.000%')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Amount to buy Put (in USD)</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {numeral(data.totalOptionsToBuyInUSD).format('$0,0.00')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Amount to buy Call (in USD)</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {numeral(data.totalOptionsToBuyInUSD).format('$0,0.00')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Amount to buy Put (in {strategy.asset.symbol})</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {formatBigNumber(data.totalOptionsToBuyInAsset, strategy.asset.decimals, '0,0.000000')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Amount to buy Call (in {strategy.asset.symbol})</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          {formatBigNumber(data.totalOptionsToBuyInAsset, strategy.asset.decimals, '0,0.000000')}
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Investor is Empty</Table.TextCell>
                    <Table.TextCell textAlign='right'>
                      {data && !isMutating && (
                        <strong>
                          <Tooltip
                            content={`Investor has ${formatBigNumber(data.investorWalletBalance, strategy.asset.decimals, '0,0.000000')} ${strategy.asset.symbol}`}
                          >
                            {data.investorWalletEmpty
                              ? (
                                <TickCircleIcon color='success' marginRight={16} />
                                )
                              : (
                                <WarningSignIcon color='warning' marginRight={16} />
                                )}
                          </Tooltip>
                        </strong>
                      )}
                    </Table.TextCell>
                  </Table.Row>

                  <Table.Row>
                    <Heading size={500} margin={16} paddingTop={16}>
                      Links
                    </Heading>
                  </Table.Row>

                  <Table.Row>
                    <Table.TextCell>
                      <Link
                        href={`https://etherscan.io/address/${strategy.configuration}`} target='_blank'
                        title='See on Etherscan'
                        rel='noopener noreferrer'
                      >
                        ConfigurationManager
                      </Link>
                      &nbsp;
                      &nbsp;
                      <CopyToClipboard text={strategy.configuration} titleIdle='Copy address'>
                        <DuplicateIcon size={12} cursor='pointer' />
                      </CopyToClipboard>
                      &nbsp;
                      &nbsp;
                      <Tooltip content='See on Etherscan'>
                        <Pane display='inline'>
                          <Link
                            href={`https://etherscan.io/address/${strategy.configuration}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            color='neutral'
                          >
                            <ShareIcon size={12} cursor='pointer' />
                          </Link>
                        </Pane>
                      </Tooltip>
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>
                      <Link
                        href={`https://app.safe.global/home?safe=eth:${strategy.investor}`}
                        target='_blank'
                        title='See on Gnosis Safe'
                        rel='noopener noreferrer'
                      >
                        Investor
                      </Link>
                      &nbsp;
                      &nbsp;
                      <CopyToClipboard text={strategy.investor} titleIdle='Copy address'>
                        <DuplicateIcon size={12} cursor='pointer' />
                      </CopyToClipboard>
                      &nbsp;
                      &nbsp;
                      <Tooltip content='See on Etherscan'>
                        <Pane display='inline'>
                          <Link
                            href={`https://etherscan.io/address/${strategy.investor}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            color='neutral'
                          >
                            <ShareIcon size={12} cursor='pointer' />
                          </Link>
                        </Pane>
                      </Tooltip>
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>
                      <Link
                        href={`https://app.safe.global/eth:${strategy.controller}/home`} target='_blank'
                        title='See on Gnosis Safe'
                        rel='noopener noreferrer'
                      >
                        Controller
                      </Link>
                      &nbsp;
                      &nbsp;
                      <CopyToClipboard text={strategy.controller} titleIdle='Copy address'>
                        <DuplicateIcon size={12} cursor='pointer' />
                      </CopyToClipboard>
                      &nbsp;
                      &nbsp;
                      <Tooltip content='See on Etherscan'>
                        <Pane display='inline'>
                          <Link
                            href={`https://etherscan.io/address/${strategy.controller}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            color='neutral'
                          >
                            <ShareIcon size={12} cursor='pointer' />
                          </Link>
                        </Pane>
                      </Tooltip>
                    </Table.TextCell>
                  </Table.Row>
                </Pane>
              </Pane>

              <Pane display='flex' marginY={32}>
                <Pane elevation={1} flex={1} marginX={12}>
                  <Heading size={500} margin={16} paddingTop={16}>
                    Queued Deposits
                    &nbsp;
                    <CopyToClipboard text={JSON.stringify(strategy.queuedDeposits)}>
                      <DuplicateIcon size={12} cursor='pointer' />
                    </CopyToClipboard>
                  </Heading>

                  <Pane margin={16}>
                    <Textarea
                      name='queued-deposits'
                      margin={0}
                      resize='none'
                      width='100%'
                      rows={10}
                      defaultValue={JSON.stringify(strategy.queuedDeposits)}
                    />
                  </Pane>
                </Pane>

                <Pane elevation={1} flex={1} marginX={12}>
                  <Heading size={500} margin={16} paddingTop={16}>
                    Contract ABI
                    &nbsp;
                    <CopyToClipboard text={JSON.stringify(strategy.vaultABI)}>
                      <DuplicateIcon size={12} cursor='pointer' />
                    </CopyToClipboard>
                  </Heading>

                  <Pane margin={16}>
                    <Textarea
                      name='abi'
                      margin={0}
                      resize='none'
                      width='100%'
                      rows={10}
                      defaultValue={JSON.stringify(strategy.vaultABI)}
                    />
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export async function getServerSideProps (context) {
  const { address } = context.params
  const strategy = await getStrategyByAddress(address)

  if (strategy) {
    return {
      props: {
        strategy
      }
    }
  }

  // Case the address was not found in vaults.js
  return {
    notFound: true
  }
}
